import React, { useLayoutEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { CircularProgress, Toolbar, Button } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useResolutionCheck, FormElements } from 'web-components';

import { resetPassword as actionResetPassword, resetUserUpdateLoadingState } from '../../../redux/user/actions';
import { ResetPasswordSchema } from '../../../attrs/formValidation';
import { isError, isLoading } from '../../../helpers/utils';
import FormAlert from '../FormAlert';
import { StyledFooterBar, StyledFooterBarContainer } from '../../../elements';

const ResetPasswordForm = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const dispatch = useDispatch();

  const { isMobile } = useResolutionCheck();
  const { updateLoadingState } = useSelector(state => state.user.user);
  const handleResetPassword = values => dispatch(actionResetPassword(values));
  const isSubmitting = isLoading(updateLoadingState.status);

  // Resets loading/error state
  useLayoutEffect(
    () => () => {
      dispatch(resetUserUpdateLoadingState());
    },
    [dispatch]
  );

  return (
    <Formik
      initialValues={{ password: '', confirm_password: '', prev_password: '' }}
      validationSchema={ResetPasswordSchema}
      onSubmit={values => handleResetPassword(values)}
    >
      {({ values, dirty, handleReset, handleChange, handleBlur, handleSubmit, errors, touched }) => (
        <form>
          <FormElements.TextField
            label={t('profile.prev_password')}
            fullWidth
            id="prev_password"
            margin="normal"
            name="prev_password"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.prev_password}
            required
            type="password"
            error={errors.prev_password && touched.prev_password}
            helperText={
              errors.prev_password &&
              touched.prev_password &&
              errors.prev_password &&
              touched.prev_password &&
              t(`form.validate.${errors.prev_password}`)
            }
          />
          <FormElements.TextField
            label={t('profile.new_password')}
            fullWidth
            id="password"
            margin="normal"
            name="password"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.password}
            required
            style={{ marginTop: '2.5rem' }}
            type="password"
            error={errors.password && touched.password}
            helperText={
              errors.password &&
              touched.password &&
              errors.password &&
              touched.password &&
              t(`form.validate.invalid_type_password`)
            }
          />
          <FormElements.TextField
            label={t('profile.confirm_password')}
            fullWidth
            id="confirm_password"
            margin="normal"
            name="confirm_password"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.confirm_password}
            required
            type="password"
            error={errors.confirm_password && touched.confirm_password}
            helperText={
              errors.confirm_password &&
              touched.confirm_password &&
              errors.confirm_password &&
              touched.confirm_password &&
              t(`form.validate.confirm_password`)
            }
          />

          {isError(updateLoadingState.status) && <FormAlert message={t('errors.user.reset_password')} />}

          <StyledFooterBar position={isMobile ? 'sticky' : 'fixed'}>
            <Toolbar>
              <StyledFooterBarContainer maxWidth="md" theme={theme}>
                <Button color="primary" disabled={!dirty || isSubmitting} onClick={handleReset}>
                  {t('form.reset')}
                </Button>
                <Button
                  disabled={isSubmitting || Object.keys(errors).length !== 0 || !dirty}
                  endIcon={
                    isSubmitting && <CircularProgress color="inherit" size={14} style={{ marginLeft: '1rem' }} />
                  }
                  color="primary"
                  onClick={handleSubmit}
                  variant="contained"
                  type="submit"
                >
                  {t('form.apply')}
                </Button>
              </StyledFooterBarContainer>
            </Toolbar>
          </StyledFooterBar>
        </form>
      )}
    </Formik>
  );
};

export default ResetPasswordForm;
