import React, { useCallback, useEffect, useState } from 'react';
import { compose } from 'redux';
import { useTranslation } from 'react-i18next';
import { Divider, Grid, Tab, Tabs, useMediaQuery } from '@mui/material';

import { Link, useHistory, useLocation, useParams } from 'react-router-dom';

import { NotifyTypo } from 'web-components';
import { orderBy } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import withLoadingState from '../../../helpers/withLoadingState';
import MachineCard from '../../../components/MachineCard/MachineCard';
import LastUpdated from '../../../components/elements/LastUpdated';
import Breadcrumbs from '../../../components/elements/Breadcrumbs';

import { getSelectedMachine, getSelectedMachineSensors } from '../../../redux/machines/selectors';
import { getData, getMetricsMachinesReducerState } from '../../../redux/rootSelectors';

import { loadMetricsMachines } from '../../../redux/metrics_machines/actions';
import { putRequestMachineDataExport } from '../../../redux/data_exports/actions';

import { ContainerTitle } from '../elements';
import StyledButton from '../../../components/elements/StyledButton';

import DateExportDialog from '../../../components/DateExportDialog';

import { ContainerPageTitle, ContainerWrapper, PageHeader, StyledContainer } from './elements';

import Sensor from './Sensor';
import Metrics from './Metrics/index';
import GraphsTemplates from './GraphsTemplates';

const MachineDetail = () => {
  const { t } = useTranslation();

  const history = useHistory();
  const { id } = useParams();
  const location = useLocation();

  const dispatch = useDispatch();
  const isMobile = useMediaQuery('(max-width:425px)');
  const machine = useSelector(compose(getData, getSelectedMachine));
  const error = useSelector(compose(state => state.unresolevdError, getSelectedMachine));
  const sensors = useSelector(compose(getData, getSelectedMachineSensors));

  const isDataMachineExport = true;

  const metricsMachines = useSelector(compose(getData, getMetricsMachinesReducerState));

  const sensorsWithTranslatedNames = (sensors || []).map(item => ({
    ...item
  }));

  const sensorsSortedByStaticAndName = orderBy(sensorsWithTranslatedNames, item => [item.is_static, item.name], [
    'desc',
    'asc'
  ]);
  const hasSensors = sensorsSortedByStaticAndName.length > 0;

  const breadcrumbObj = [
    { route: '/dashboard', name: `${t('dashboard.title')}` },
    { route: null, name: `${machine.name}` }
  ];

  const [openExportDialog, setOpenExportDialog] = useState(false);

  const handleExportData = () => {
    setOpenExportDialog(true);
  };

  const handleCloseExportDataDialog = () => {
    setOpenExportDialog(false);
  };

  useEffect(() => {
    dispatch(loadMetricsMachines(machine.id));
  }, [dispatch, machine.id]);

  const tabsMap = {
    sensors: 0,
    'graph-templates': 1,
    metrics: 2
  };

  const getDefaultTab = useCallback(() => {
    const pathSegment = location.pathname.split('/').pop();
    return tabsMap[pathSegment] !== undefined ? tabsMap[pathSegment] : 0;
  }, [location.pathname, tabsMap]);

  const [selectedTab, setSelectedTab] = useState(getDefaultTab());

  useEffect(() => {
    setSelectedTab(getDefaultTab());
  }, [getDefaultTab]);

  const handleTabChange = (event, newValue) => {
    const tabPath = Object.keys(tabsMap).find(key => tabsMap[key] === newValue);
    history.push(`/dashboard/${id}/${tabPath}`);
  };

  const tabContent = [
    {
      index: 0,
      title: t('machines.detail.tabs.sensors.title'),
      content: <Sensor machine={machine} sensors={sensors} hasSensors={hasSensors} />
    },
    {
      index: 1,
      title: t('machines.detail.tabs.templates.title'),
      content: <GraphsTemplates machine={machine} sensors={sensors} />
    },
    {
      index: 2,
      title: t('machines.detail.tabs.metrics.title'),
      content: <Metrics machine={machine} metricsMachines={metricsMachines} />
    }
  ];

  const handleSetValuesDateExportPeriod = (
    newStartDateTimeText,
    newFinalDateTimeText,
    FileFormatParam,
    SamplePeriodParam,
    TimezoneParam
  ) => {
    const values = {
      action: FileFormatParam,
      date_from: newStartDateTimeText,
      date_to: newFinalDateTimeText,
      decimal_separator: '.',
      timezone: TimezoneParam,
      window: SamplePeriodParam
    };
    dispatch(putRequestMachineDataExport(machine.id, values));
  };

  return (
    <>
      <PageHeader alwaysVisible>
        <Breadcrumbs data={breadcrumbObj} />
        <ContainerPageTitle>
          <PageHeader.Title>
            <ContainerTitle>
              <PageHeader.Title.Main>{t('machines.detail.machineOverview')}</PageHeader.Title.Main>
            </ContainerTitle>
            <LastUpdated type="machine" />
          </PageHeader.Title>
          <StyledButton
            iconName="export"
            buttonText={t('machines.detail.export_data')}
            isMobile={isMobile}
            variant="outlined"
            color="primary"
            size="medium"
            data-selector="machine-details-export-data-button"
            onClick={handleExportData}
            style={{ padding: '1rem' }}
          />
        </ContainerPageTitle>
      </PageHeader>

      <StyledContainer>
        <ContainerWrapper className="withLastUpdated" maxWidth={false}>
          <Grid container spacing={3} style={{ marginTop: isMobile ? '7rem' : '5rem' }}>
            <Grid item xs={12} sm={6} lg={3}>
              <NotifyTypo.Heading2Regular gutterBottom style={{ marginTop: '2rem', marginBottom: '1rem' }}>
                {t('machines.detail.machine')}
              </NotifyTypo.Heading2Regular>
              <Link
                style={{
                  display: 'block',
                  textDecoration: 'none',
                  minHeight: 70,
                  maxWidth: 400
                }}
                to={`/dashboard/${machine.id}/history`}
              >
                <MachineCard showError unresolvedError={error.data} withName machine={machine} withAlertSign />
              </Link>
            </Grid>
          </Grid>
          <Divider style={{ marginBottom: '1.5rem', marginTop: '3rem' }} />
          <Tabs value={selectedTab} onChange={handleTabChange}>
            {tabContent.map(tb => (
              <Tab
                label={tb.title}
                key={tb.index}
                sx={{
                  textTransform: 'capitalize',
                  borderBottom: '1px solid #ccc',
                  padding: '10px 16px',
                  minHeight: '0px'
                }}
              />
            ))}
          </Tabs>
          {tabContent[selectedTab].content}
        </ContainerWrapper>
      </StyledContainer>
      <DateExportDialog
        isMachineDetail
        open={openExportDialog}
        handleClose={handleCloseExportDataDialog}
        title={t('dialog.data_exports.title')}
        subTitle={t('dialog.data_exports.subtitle')}
        startTimeCaption={t('dialog.data_exports.time_from')}
        endTimeCaption={t('dialog.data_exports.time_to')}
        cancelCaption={t('default_actions.cancel')}
        okCaption={t('dialog.data_exports.export')}
        setValues={handleSetValuesDateExportPeriod}
        showDatePicker
        showTimePicker
        isDataMachineExport={isDataMachineExport}
        maxDays={7}
        maxWidth="688px"
      />
    </>
  );
};

export default withLoadingState(MachineDetail);
